/*
 * Copyright 2015-2020 The OpenZipkin Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the License
 * is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
 * or implied. See the License for the specific language governing permissions and limitations under
 * the License.
 */

html {
  /* Include padding and border in the element's total width and height: */
  box-sizing: border-box;
  font-size: 14px;
}

body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  color: #686868;
  background-color: #ffffff;
  margin: 0;
}

[role="button"] {
  cursor: pointer;
}
